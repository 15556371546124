const Assets = {
  // placeholder
  water_mark: {
    img: require('../assets/placeholderImages/pickzon_water_mark.svg').default,
    alt: "Pickzon"
  },
  logo: {
    img: require("../assets/placeholderImages/pickzon_logo.svg").default,
    alt: "Pickzon"
  },
  coming_soon: {
    img: require("../assets/placeholderImages/coming_soon.png"),
    alt: "Pickzon-coming-soon"
  },
  page_not_found: {
    img: require("../assets/placeholderImages/404.svg").default,
    alt: "Pickzon-error-404"
  },
  server_down: {
    img: require("../assets/placeholderImages/503.png"),
    alt: "Pickzon-service-unavailable"
  },
  no_data: {
    img: require("../assets/placeholderImages/no_data.png"),
    alt: "Pickzon-relaod-QR-image"
  },
  // header
  logo_white: {
    img: require('../assets/placeholderImages/pickzon_white_logo.svg').default,
    alt: "Pickzon-logo"
  },
  logo_black: {
    img: require('../assets/placeholderImages/pickzon_black_logo.svg').default,
    alt: "Pickzon-logo"
  },
  // footer
  trade_mark_logo: {
    img: require('../assets/placeholderImages/trademark_logo.svg').default,
    alt: "Pickzon_trademark"
  },
  play_store: {
    img: require('../assets/placeholderImages/google-play-store.svg').default,
    alt: "playStore"
  },
  app_store: {
    img: require('../assets/placeholderImages/apple-store.svg').default,
    alt: "appStore"
  },

  handLove: {
    img: require('../assets/placeholderImages/home/handLove.svg').default,
    alt: "handLove"
  },

  peopleLove: {
    img: require('../assets/placeholderImages/home/peopleLove.svg').default,
    alt: "peopleLove"
  },

  groupMobile: {
    img: require('../assets/placeholderImages/home/groupMobile.svg').default,
    alt: "groupMobile"
  },

  aboutUsImg: {
    img: require('../assets/placeholderImages/about/aboutUs.svg').default,
    alt: "aboutUs"
  },
  facebook: {
    img: require('../assets/placeholderImages/footer/fb.svg').default,
    alt: "Fabebook"
  },
  instagram: {
    img: require('../assets/placeholderImages/footer/insta.svg').default,
    alt: "instagram"
  },
  linkedIn: {
    img: require('../assets/placeholderImages/footer/linkedin.svg').default,
    alt: "linkedIn"
  },
  x: {
    img: require('../assets/placeholderImages/footer/x.svg').default,
    alt: "X"
  },
  QR: {
    img: require('../assets/placeholderImages/footer/QR.svg').default,
    alt: "QR"
  },
  community: {
    img: require('../assets/placeholderImages/community/community.svg').default,
    alt: "community"
  },
  homeBlog1: {
    img: require('../assets/placeholderImages/blogs/image1.svg').default,
    alt: "homeBlog1"
  },
  homeBlog2: {
    img: require('../assets/placeholderImages/blogs/image2.svg').default,
    alt: "homeBlog2"
  },
  homeBlog3: {
    img: require('../assets/placeholderImages/blogs/image3.svg').default,
    alt: "homeBlog3"
  },
  homeBlog4: {
    img: require('../assets/placeholderImages/blogs/image4.svg').default,
    alt: "homeBlog4"
  },

};
module.exports = { Assets };
