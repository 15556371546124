import React, { memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Box, Typography, Button, MenuItem, MenuList, Paper, Divider } from "@mui/material";
import { Menu } from "@mui/icons-material";

import { installAppBtn } from "../../utils/common";

const HambugerMenu = ({ navLinks }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);

  const onHandleLink = (path) => {
    navigate(path);
  }

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (<>
    <IconButton sx={{ ml: "auto", fontSize: "2rem" }} color="inherit"
      onClick={() => setOpen(!open)}
    > <Menu />
    </IconButton>

    {open && (
      <div
        ref={menuRef}
        id=""
        style={{
          position: 'absolute',
          top: '49px',
          right: '18px',
          padding: '5px',
          width: '150px',
          textAlign: 'center',
          // display: open ? 'block' : 'none',
        }}>
        <Paper elevation={3}>
          <MenuList>
            {navLinks.map((item, i) => {
              return (
                <>
                  <MenuItem onClick={() => onHandleLink(item.path)}
                    sx={{ minHeight: 0, pt: 0, pb: 0 }}>{item.label}</MenuItem>
                  <Divider />
                </>
              );
            })}
            <Box sx={{ mt: "auto", p: "1px" }}>
              <Button variant="contained" onClick={() => installAppBtn()} sx={{ bgcolor: '#F11A7B' }}>
                <Typography color="inherit" sx={{ fontSize: '10px' }}>
                  Install Now
                </Typography>
              </Button>
            </Box>
          </MenuList>
        </Paper>
      </div>
    )}

    {/* <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)} >
      <Box sx={{ width: "300px" }}>
        <Box display={"flex"} alignItems={"center"}>
           <IconButton sx={{ ml: "auto" }} size="large" color="inherit" onClick={() => setOpen(!open)}>
            <Close sx={{ fontSize: "2rem" }} />
          </IconButton>
        </Box>
        <Box>
          <List>
            {navLinks.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  {item.status === 0 &&
                    <ListItem component={Link} to={item.path} onClick={() => onHandleLink(item.path)} sx={{ backgroundColor: location.pathname === item.path ? "#EEF6FF" : null }}>
                      <ListItemText>
                        <Typography variant="span" fontSize={"1.3rem"} color={location.pathname === item.path ? "secondary.main" : "primary.100"}>
                          {item.label}
                        </Typography>
                      </ListItemText>
                    </ListItem>}
                </React.Fragment>
              );
            })}
          </List>
        </Box>
      </Box>
      <Box sx={{ mt: "auto", p: "1rem" }}>
        <Button variant="contained"
          sx={{
            width: "100%",
            background: `linear-gradient(to left,${color.blueAccent[300]},${color.blueAccent[500]})`,
            textTransform: "none",
            "&:hover": {
              bgcolor: "secondary.main",
            },
          }}
          onClick={() => installAppBtn()}>
          <Typography variant="h6" color="inherit">
            Install Now
          </Typography>
        </Button>
      </Box>
    </Drawer> */}
  </>);
};

export default memo(HambugerMenu);