import React from "react"
import { useNavigate } from "react-router-dom";

import {
  AppBar,
  Box,
  Toolbar,
  useTheme,
  useMediaQuery,
  Typography
} from '@mui/material';

import { tokens } from "../../theme/theme";
import { Assets } from "../../assets/Assets";
import NavigationPaths from "../../routes/navigationPath";
import { HeaderLinks, MobileHeaderLinks } from "../../routes/headerLinks";
import NavbarMenu from "../navbar/navbar";
import HamburgerMenu from "../navbar/hamburgerMenu"


const color = tokens();
const Header = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("lg"));
  const colorGrade = theme.palette.mode === 'dark' ? 200 : 900;

  const onTabLogo = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return navigate(NavigationPaths.HOME);
  };

  return (
    <AppBar
      position="sticky"
      color="inherit"
      sx={{
        backgroundImage: 'none',
        boxShadow: `0px 1px 0px 0px  ${color.primary[colorGrade]}`
      }}>
      <Toolbar component={'nav'} sx={{ padding: { xs: "8px 15px 8px !important", md: "8px 85px 8px !important" } }}>
        <Box onClick={onTabLogo} sx={{ cursor: "pointer" }}>
          <img src={Assets.logo.img} alt={Assets.logo.alt} style={{ height: '27px' }} />
          <Typography fontSize={{ xs: "9.5px", md: "0.6rem" }} fontFamily={{ xs: "Inter", md: "Inter" }} fontWeight={{ xs: "bold", md: "bold" }} align="right" color={"primary.100"}>
            Connecting Hearts</Typography>
        </Box>
        {isMatch ? (
          <HamburgerMenu navLinks={MobileHeaderLinks} />
        ) : (
          <NavbarMenu navLinks={HeaderLinks} />
        )}
      </Toolbar>
    </AppBar>
  )
}

export default Header