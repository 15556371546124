import React from 'react';
import { Box, Card, Skeleton, Stack } from '@mui/material';
import Cookies from 'js-cookie'

import getBrowserLocation from "./deviceLocation"

export const setToken = (name, value, option) => {
  return Cookies.set(name, JSON.stringify(value), option) || null;
};

export const getToken = (name) => {
  return Cookies.get(name) || null;
};

export const removeToken = (name) => {
  window.location.href = "/";
  return Cookies.remove(name) || null;
};

export const removeUserSession = () => {
  localStorage.clear();
  sessionStorage.clear();
};


export const BlogCardplaceholder = ({ listCount }) => {
  return (
    <>
      {Array(listCount).fill(
        <>
          <Card sx={{ width: "350px", m: "1rem " }}>
            <Stack spacing={1}>
              <Skeleton sx={{ height: 350 }} animation="wave" variant="rectangular" />
            </Stack>
            <Box sx={{ m: "1rem" }}>
              <Skeleton animation="wave" height={20} style={{ marginBottom: 8 }} />
              <Skeleton animation="wave" height={20} style={{ marginBottom: 8 }} />
              <Skeleton animation="wave" height={20} style={{ marginBottom: 8 }} />
              <Skeleton animation="wave" height={20} style={{ marginBottom: 8 }} />
              <Skeleton animation="wave" height={20} style={{ marginBottom: 8 }} />
              <Skeleton animation="wave" height={20} width="80%" />
            </Box>
          </Card>
        </>
      ).map((item, i) => {
        return (<React.Fragment key={i}>
          {item}
        </React.Fragment>)
      })}
    </>
  )
};

export const BlogDeatilsPlaceholder = ({ listCount }) => {
  return (
    <>
      {Array(listCount).fill(
        <>
          <Card sx={{ width: "350px", m: "1rem " }}>
            <Stack spacing={1}>
              <Skeleton
                sx={{ height: 200 }}
                animation="wave"
                variant="rectangular"
              />
            </Stack>
            <Box sx={{ m: "1rem" }}>
              <Skeleton animation="wave" height={20} style={{ marginBottom: 8 }} />
              <Skeleton animation="wave" height={20} style={{ marginBottom: 8 }} />
              <Skeleton animation="wave" height={20} width="80%" />
            </Box>
          </Card>
        </>
      ).map((item, i) => {
        return (<React.Fragment key={i}>
          {item}
        </React.Fragment>)
      })}
    </>
  )
}

// //to install app regarding device OS
export const installAppBtn = async () => {
  const obj = { ...await getBrowserLocation() }
  let _link = "https://play.google.com/store/apps/details?id=com.chat.pickzon"
  if (obj.OS === ('iPhone' || 'iPad')) {
    _link = "https://apps.apple.com/in/app/pickzon/id1560097730"
  };
  window.open(`${_link} `, '_blank');
}