import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Box, List, ListItem, ListItemText, Typography, } from "@mui/material";

const NavbarMenu = ({ navLinks }) => {
  const location = useLocation();
  return (
    <Box sx={{ ml: "auto", display: "flex" }}>
      {navLinks.map((item) => {
        return (<React.Fragment key={item.label}>
          {item.status === 0 && (
            <List>
              <ListItem component={Link} to={item.path}>
                <ListItemText >
                  <Typography
                    variant="span"
                    fontSize={"18px"}
                    fontWeight={500}
                    fontFamily={'Poppins'}
                    color={location.pathname === item.path ? "#F11A7B" : "#242323"}
                  >
                    {item.label}
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>)
          }
          {item.status === 1 && (
            <Button variant="contained">{item.label}</Button>
          )}
        </React.Fragment>);
      })}
    </Box >
  );
};

export default React.memo(NavbarMenu);