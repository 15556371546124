import NavigationPaths from "./navigationPath";
const HeaderLinks = [
    { path: NavigationPaths.BLOGS, label: "BLOG", status: 0 },
    { path: NavigationPaths.ABOUTUS, label: "ABOUT", status: 0 },
    { path: NavigationPaths.CONTACT, label: "CONTACT", status: 0 },
    { path: NavigationPaths.JOBS, label: "JOBS", status: 0 },
    // { path: NavigationPaths.LOGIN, label: "SIGN UP", status: 1 },
];
const MobileHeaderLinks = [
    { path: NavigationPaths.BLOGS, label: "BLOG", status: 0 },
    { path: NavigationPaths.ABOUTUS, label: "ABOUT", status: 0 },
    { path: NavigationPaths.CONTACT, label: "CONTACT", status: 0 },
    { path: NavigationPaths.JOBS, label: "JOBS", status: 0 },
    // { path: NavigationPaths.LOGIN, label: "SIGN UP", status: 1 },
]
export { HeaderLinks, MobileHeaderLinks };