import React from "react";

import { BrowserRouter } from "react-router-dom";

import Header from "../header";
import Footer from "../footer";
import Main from "../main/index";

const Layout = () => {

  return (
    <>
      <BrowserRouter>
        <Header />
        <Main />
        <Footer />
      </BrowserRouter>
    </>
  )
}

export default Layout