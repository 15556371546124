const NavigationPaths = {
  HOME: "/",
  ABOUTUS: "/about",
  OPENSHORTAPPURL: "/apps",
  FEATURE: "/features",
  SECURITY: "/security",
  WHYPICKZONE: "/why-pickzon",
  FAQ: "/faq",
  LOGIN: "/login",
  CONTACT: "/contact-us",
  JOBS: "/jobs",
  PRIVACYPOLICY: "/privacy-policy",
  TERM: "/terms",
  COMMUNITYGUIDELINES: "/community-guidelines",
  IPROPERTYPOLICY: "/intellectual-property-policy",
  SEARCHEXPLORE: "/search-and-explore",
  SOCIALCREATOR: "/social-creator",
  FEEDCONTENT: "/feed-content",
  BUSINESSPAGE: "/best-social-media-app-for-business",
  USERGREENBADGEPOLICY: "/user-green-badge-policy",
  PAGEVERIFICATIONBADGEPOLICY: "/page-verification-badge-policy",
  CAREER: "/career",
  CHEERSPOLICY: "/cheers-policy",
  APPCHEERSPOLICY: "/app-cheers-policy",
  CANCELLATIONREFUNDPOLICY: "/cancellation-and-refund-policy",
  SHIPPINGANDEXCHANGEPOLICY: "/shipping-and-exchange-policy",
  NOTIFICATIONS: "/notifications",
  BLOGS: "/blogs",
  BLOGDETAILS: "/blog/:slug",
  FEED: "/feeds",
  SEARCH: "/search",
  PAGE: "/pages",
  MYPAGES: "/my-pages",
  FOLLOWINGPAGES: "/following-pages",
  SAVEFEED: "/save-feed",
  POST: "/post/",
  LEADERBOARD: "/leaderboard",
  USERPROFILE: "/profile/",
  PAGEPROFILE: "/page/",
  ID: ":id",
  COMINGSOON: "/coming-soon",
  APPCOMINGSOON: "/app-coming-soon",
  VALENTINELEADERBOARD: "/app-valentine-leaderboard",

};

export default NavigationPaths;